import React from "react";
import MainComponent from "../Component/MainComponent";
import Reveal from "../Component/Animation/Reveal";

const Live = () => {
  const text = [
    '>_ Initializing "Claim Page" sequence...\n',
    ">_ ",
    <span className="text-[#4284FF]">STATUS:</span>,
    " Feature ",
    <span className="text-[#22C55E]">READY</span>,
    ".\n>_ Snapshot Confirmed at Block Height: ",
    <span className="text-[#4284FF]">32,747,651</span>,
    "\n",
    <span className="text-[#4284FF]">SYSTEM MESSAGE:</span>,
    ' "Inscription owners can now claim their token"\n\n',
    ">_ Verifying wallet status...\n",
    ">_ AUTHENTICATION: Awaiting wallet connection...\n",
    "   /.. ",
    <span className="text-[#4284FF]">[</span>,
    <span className="text-[#4284FF] hover:underline cursor-pointer">
      Connect Wallet
    </span>,
    <span className="text-[#4284FF]">]</span>,
  ];

  return (
    <MainComponent>
      <div className="flex relative flex-col w-full h-screen items-center justify-center p-[16px]">
        <video
          muted
          autoPlay
          loop
          className="absolute w-full h-full object-cover top-0"
        >
          <source src="/animations/depins_grid_3.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute w-full h-screen bg-black bg-opacity-80 backdrop-blur-sm top-0" />
        <Reveal className="flex w-full h-screen items-center justify-center pt-24">
          <div className="border-2 border-[#4284FF] w-[704px] p-[32px] bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)]">
            <p className="whitespace-pre-wrap">
              {text.map((part, index) => (
                <React.Fragment key={index}>{part}</React.Fragment>
              ))}
            </p>
          </div>
        </Reveal>
      </div>
    </MainComponent>
  );
};

export default Live;
