import React from "react";
import { Icon } from "@iconify/react";

const Footer = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center p-[32px] gap-[24px] text-sm">
      <div className="flex w-full justify-between">
        <img
          src="/images/header-logo.png"
          className="h-[24px]"
          alt="header-logo"
        />
        <div className="flex items-center gap-[24px]">
          <a
            href="https://iotexscan.io/token/0x2716789482ea21b15ee9bb6c07dc8251150f36f6?format=0x"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="heroicons:document-solid" height={16} />
          </a>
          <a
            href="http://t.me/DePINsCTO"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="mage:telegram" height={16} />
          </a>
          <a
            href="https://x.com/depinsapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="prime:twitter" height={16} />
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 justify-center w-full gap-[8px] items-center">
        <p className="sm:text-start text-center">
          Contact: depinscto@gmail.com
        </p>
        <p className="text-center">
          CA: 0x2716789482EA21B15eE9bb6c07dc8251150f36f6
        </p>
        <p className="sm:text-end text-center">{`© ${new Date().getFullYear()} Depins. All Rights Reserved.`}</p>
      </div>
    </div>
  );
};

export default Footer;
