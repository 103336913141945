import React, { useEffect, useState } from "react";
import Reveal from "../Animation/Reveal";
import { db } from "../../firebase-config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAccount } from "wagmi";
import useShorten from "../../hooks/shorten";
import { ethers } from "ethers";

const Earnings = () => {
  const [headerData, setHeaderData] = useState([
    {
      id: "currentDePINsStaked",
      title: "Current DePINs Staked",
      value: 0,
    },
    {
      id: "totalDePINsEarned",
      title: "Total DePINs Earned",
      value: 0,
    },
    // {
    //   id: "totalBinobitEarned",
    //   title: "Total Binobit Earned",
    //   value: 0,
    // },
  ]);
  const [tableData, setTableData] = useState([]);

  const { address } = useAccount();
  const shorten = useShorten();

  const dayDifference = (dateInSeconds) => {
    const stakeDate = new Date(dateInSeconds * 1000);
    const currentDate = new Date();

    const differenceInTime = currentDate.getTime() - stakeDate.getTime();

    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };

  const fetchTableData = async () => {
    try {
      const q = query(
        collection(db, "staking"),
        where("walletAddress", "==", address)
      );

      const querySnapshot = await getDocs(q);

      let data = [];

      querySnapshot.forEach((doc) => {
        const docId = shorten(doc.id);
        const docData = doc.data();

        const concatData = {
          ...docData,
          id: docId,
        };

        data.push(concatData);

        setHeaderData((prev) => [
          {
            ...prev[0],
            value:
              !docData.unstakeHash && !docData.claimHash
                ? prev[0].value +
                  Number(ethers.formatEther(docData.stakeAmount))
                : prev[0].value,
          },
          {
            ...prev[1],
            value:
              prev[1].value +
              (docData.stakeType === 0
                ? ((Number(ethers.formatEther(docData.stakeAmount)) * 0.5) /
                    365) *
                  61
                : ((Number(ethers.formatEther(docData.stakeAmount)) * 0.15) /
                    365) *
                  (docData.daysStaked ??
                    dayDifference(docData.stakeDate.seconds))),
          },
          // {
          //   ...prev[2],
          //   value:
          //     prev[2].value +
          //     (docData.stakeType === 0
          //       ? Number(ethers.formatEther(docData.stakeAmount)) * 61 * 0.05
          //       : Number(ethers.formatEther(docData.stakeAmount)) *
          //         (docData.daysStaked ??
          //           dayDifference(docData.stakeDate.seconds)) *
          //         0.025),
          // },
        ]);
      });

      console.log("count");

      setTableData(data);
    } catch (e) {
      console.error(e);
      console.log("No data found");
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <Reveal className="flex flex-col w-full gap-[48px]">
      <div className="grid grid-cols-2 w-full">
        {headerData.map((data) => (
          <div
            className="flex flex-col items-center justify-center text-center"
            key={data.id}
          >
            <p>{data.title}</p>
            <h1
              className="text-[#4284FF] text-[31px]"
              style={{ fontFamily: "Array-Regular" }}
            >
              {data.value}
            </h1>
          </div>
        ))}
      </div>
      <div className="border-2 border-[#4284FF] flex flex-col w-full bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)] overflow-x-auto text-nowrap">
        <table>
          <thead>
            <tr className="border-b border-[#FFFFFF33] text-[#FFFFFFCC]">
              <th className="font-normal py-[12px] px-[16px]">#</th>
              <th className="font-normal py-[12px] px-[16px]">Staking</th>
              <th className="font-normal py-[12px] px-[16px]">APY</th>
              <th className="font-normal py-[12px] px-[16px]">Staked Amount</th>
              <th className="font-normal py-[12px] px-[16px]">DePINs Earned</th>
              {/* <th className="font-normal py-[12px] px-[16px]">
                Binobit Earned
              </th> */}
              <th className="font-normal py-[12px] px-[16px]">Stake</th>
              <th className="font-normal py-[12px] px-[16px]">Unstake</th>
              <th className="font-normal py-[12px] px-[16px]">Withdrawal</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((data, index) => (
                <tr key={data.stakeHash}>
                  <td className="py-[12px] px-[16px]">{index + 1}</td>
                  <td className="py-[12px] px-[16px]">
                    {data.stakeType === 0 ? "Locked" : "Flexible" ?? "-"}
                  </td>
                  <td className="py-[12px] px-[16px]">
                    {data.stakeType === 0 ? "50%" : "15%" ?? "-"}
                  </td>
                  <td className="py-[12px] px-[16px]">
                    {Number(ethers.formatEther(data.stakeAmount)) ?? "-"}
                  </td>
                  <td className="py-[12px] px-[16px]">
                    {data.stakeType === 0
                      ? ((Number(ethers.formatEther(data.stakeAmount)) * 0.5) /
                          365) *
                        61
                      : ((Number(ethers.formatEther(data.stakeAmount)) * 0.15) /
                          365) *
                        (data.daysStaked ??
                          dayDifference(data.stakeDate.seconds))}
                  </td>
                  {/* <td className="py-[12px] px-[16px]">
                    {data.stakeType === 0
                      ? Number(ethers.formatEther(data.stakeAmount)) * 61 * 0.05
                      : Number(ethers.formatEther(data.stakeAmount)) *
                        (data.daysStaked ??
                          dayDifference(data.stakeDate.seconds)) *
                        0.025}
                  </td> */}
                  <td className="py-[12px] px-[16px]">
                    {data.stakeDate && data.stakeHash ? (
                      <div className="flex flex-col">
                        <p>
                          {new Date(
                            data.stakeDate.seconds * 1000
                          ).toLocaleDateString()}
                        </p>
                        <a
                          href={`https://testnet.iotexscan.io/tx/${data.stakeHash}`}
                          target="_blank"
                          className="hover:underline text-[#4284FF]"
                        >
                          {shorten(data.stakeHash)}
                        </a>
                      </div>
                    ) : (
                      <div>
                        <p>-</p>
                      </div>
                    )}
                  </td>
                  <td className="py-[12px] px-[16px]">
                    {data.unstakedDate && data.unstakeHash ? (
                      <div className="flex flex-col">
                        <p>
                          {new Date(
                            data.unstakedDate.seconds * 1000
                          ).toLocaleDateString()}
                        </p>
                        <a
                          href={`https://testnet.iotexscan.io/tx/${data.unstakeHash}`}
                          target="_blank"
                          className="hover:underline text-[#4284FF]"
                        >
                          {shorten(data.unstakeHash)}
                        </a>
                      </div>
                    ) : (
                      <div>
                        <p>-</p>
                      </div>
                    )}
                  </td>
                  <td className="py-[12px] px-[16px]">
                    {data.unstakedDate && data.claimHash ? (
                      <div className="flex flex-col">
                        <p>
                          {new Date(
                            data.unstakedDate.seconds * 1000
                          ).toLocaleDateString()}
                        </p>
                        <a
                          href={`https://testnet.iotexscan.io/tx/${data.claimHash}`}
                          target="_blank"
                          className="hover:underline text-[#4284FF]"
                        >
                          {shorten(data.claimHash)}
                        </a>
                      </div>
                    ) : (
                      <div>
                        <p>-</p>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Reveal>
  );
};

export default Earnings;
