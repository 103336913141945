import React, { useState } from "react";
import MainComponent from "../Component/MainComponent";
import Button from "../Component/Button";
import Reveal from "../Component/Animation/Reveal";
import LockedForm from "../Component/Staking/LockedForm";
import FlexibleForm from "../Component/Staking/FlexibleForm";
import WalletModal from "../Component/WalletModal";
import Earnings from "../Component/Staking/Earnings";
import { useAccount } from "wagmi";

const Staking = () => {
  const [selectedStake, setSelectedStake] = useState(
    sessionStorage.getItem("selectedStake") || "locked"
  );
  const [showWalletModal, setShowWalletModal] = useState(false);

  const { address } = useAccount();

  const buttons = [
    { id: "locked", text: "Locked Staking", noAddressHide: false },
    { id: "flexible", text: "Flexible Staking", noAddressHide: false },
    { id: "earnings", text: "Earnings", noAddressHide: true },
  ];

  const toggleWalletModal = () => {
    setShowWalletModal(!showWalletModal);
  };

  return (
    <MainComponent>
      {showWalletModal && <WalletModal toggleModal={toggleWalletModal} />}
      <Reveal className="flex flex-col w-full items-center justify-center gap-[64px] my-[110px]">
        <div className="flex flex-col text-center max-w-[70%]">
          <h1
            className="text-[76px] text-[#4284FF] drop-shadow-[0_0_24px_rgba(66,132,255,1)]"
            style={{ fontFamily: "Array-Regular" }}
          >
            $DePINs Staking
          </h1>
          <p>
            Stake your $DePINs tokens to unlock rewards and participate in the
            network's growth. Choose between locked staking for higher returns
            or flexible staking for greater liquidity. Start earning today!
          </p>
        </div>
        <div className="flex flex-col w-full items-center justify-center gap-[48px] px-[16px] lg:max-w-[70%]">
          <div
            className={`grid items-center gap-[24px] w-full lg:max-w-[704px] ${
              !address ? "grid-cols-2" : "grid-cols-3"
            }`}
          >
            {buttons.map((button) =>
              button.noAddressHide && !address ? null : (
                <Button
                  key={button.id}
                  className={
                    selectedStake !== button.id
                      ? "border border-[#4284FF] bg-black h-full"
                      : " h-full"
                  }
                  variant={selectedStake === button.id ? "primary" : "tertiary"}
                  colour="blue"
                  onClick={() => {
                    sessionStorage.setItem("selectedStake", button.id);
                    setSelectedStake(button.id);
                  }}
                >
                  {button.text}
                </Button>
              )
            )}
          </div>
          {selectedStake === "locked" ? (
            <LockedForm toggleWalletModal={toggleWalletModal} />
          ) : selectedStake === "flexible" ? (
            <FlexibleForm toggleWalletModal={toggleWalletModal} />
          ) : (
            selectedStake === "earnings" && <Earnings />
          )}
        </div>
      </Reveal>
    </MainComponent>
  );
};

export default Staking;
