import React, { useState, useEffect } from "react";
import Reveal from "../Animation/Reveal";
import Marquee from "react-fast-marquee";
import Button from "../Button";
import TypeWriting from "../Animation/TypeWriting";
import { useNavigate } from "react-router-dom";
import { CLAIM, LIVE } from "../../Router/path";
import { useAccount, useReadContracts } from "wagmi";
import axios from "axios";
import iotex_token_abi from "../../hooks/abi/iotex_token_abi";
import { Icon } from "@iconify/react/dist/iconify.js";

const AnimationSection = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [headData, setHeadData] = useState([
    {
      title: "Price",
      value: "",
    },
    {
      title: "24H Volume",
      value: "",
    },
    {
      title: "Market Cap",
      value: "",
    },
    {
      title: "Holders",
      value: "",
    },
  ]);

  const { address } = useAccount();
  const { data: totalHolders } = useReadContracts({
    contracts: [
      {
        abi: iotex_token_abi,
        functionName: "totalSupply",
        address: process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
      },
    ],
  });
  const navigate = useNavigate();

  const logArray = [
    ">_ Establishing connection to DePINs network...",
    ">_ Authenticating User Credentials...",
    ">_ Access Level: ADMINISTRATOR",
    ">_ Connection established. Node ID: 4Z-21-X.",
    ">_ Synchronizing with decentralized nodes... [███---] 42%",
    ">_ Synchronizing with decentralized nodes... [███████] 100%",
    ">_ Data flow integrity: Verified",
    ">_ Loading DePINs overview...",
    ">_ Initializing DePINs Protocol Suite...",
    "  /.. Activating NODE-22: Data relay active",
    "  /.. Consensus Protocol: Proof-of-Physical-Work",
    "  /.. NODE-STATS: Online",
    "  /.. Latency: 45ms",
    "  /.. Throughput: 120mbps",
    "  /.. Energy Efficiency: 98%",
    "  /.. Network Traffic: Nominal",
    ">_ Deploying Distributed Ledger Updates...",
    ">_ Verifying Smart Contracts... [Success]",
    ">_ Secure Data Exchange Channels: Established",
    ">_ Awaiting user input for next operation...",
    ">_ Enter command: _",
  ];

  const fetchHolder = async () => {
    try {
      axios
        .get(
          'https://iotexscan.io/api/trpc/chain.getGasPrice,token.getTokenDetail?batch=1&input=%7B"0"%3A%7B"json"%3Anull%2C"meta"%3A%7B"values"%3A%5B"undefined"%5D%7D%7D%2C"1"%3A%7B"json"%3A%7B"address"%3A"io1yut839yzagsmzhhfhdkq0hyz2y2s7dhkgfj973"%7D%7D%2C"2"%3A%7B"json"%3Anull%2C"meta"%3A%7B"values"%3A%5B"undefined"%5D%7D%7D%7D'
        )
        .then((response) => {
          setHeadData((prevHeadData) =>
            prevHeadData.map((data) =>
              data.title === "Holders"
                ? {
                    ...data,
                    value:
                      response.data[1].result.data.json.holders.toLocaleString(),
                  }
                : data
            )
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHeadData = async () => {
    try {
      axios
        .get(
          "https://api.dexscreener.com/latest/dex/search?q=0x2716789482EA21B15eE9bb6c07dc8251150f36f6"
        )
        .then((response) => {
          let pairs = response.data.pairs;
          const specificPair = pairs.find(
            (pair) =>
              pair.pairAddress === "0x55675984d54BAe52354320894eA1864aF952cbbc"
          );
          if (specificPair) {
            setHeadData((prevHeadData) =>
              prevHeadData.map((data) =>
                data.title === "Price"
                  ? {
                      ...data,
                      value:
                        parseFloat(specificPair.priceUsd).toPrecision(6) +
                        " USD",
                    }
                  : data.title === "24H Volume"
                  ? { ...data, value: specificPair.volume.h24.toLocaleString() }
                  : data.title === "Market Cap"
                  ? { ...data, value: specificPair.marketCap.toLocaleString() }
                  : data
              )
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const updateBackgroundImage = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage("url('/images/grid/4.svg')");
      } else if (window.innerWidth < 1024) {
        setBackgroundImage("url('/images/grid/8.svg')");
      } else {
        setBackgroundImage("url('/images/grid/12.svg')");
      }
    };

    updateBackgroundImage();

    window.addEventListener("resize", updateBackgroundImage);

    return () => window.removeEventListener("resize", updateBackgroundImage);
  }, []);

  useEffect(() => {
    fetchHeadData();
    fetchHolder();
    const intervalId = setInterval(() => {
      fetchHeadData();
      fetchHolder();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Reveal
      className="flex flex-col px-[24px] py-[32px] lg:px-[128px] lg:py-[96px] gap-[32px]"
      style={{
        backgroundImage: backgroundImage,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Head data */}
      <div className="grid grid-cols-2 sm:grid-cols-4">
        {headData &&
          headData.map((data, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <h1 className="text-md">{data.title}</h1>
              <p
                className="text-[#4284FF] text-[32px]"
                style={{ fontFamily: "Array-Regular" }}
              >
                {data.value}
              </p>
            </div>
          ))}
      </div>

      {/* Animations */}
      <div className="relative border border-[#4284FF66] flex flex-col text-[#4284FF] md:p-[32px] p-[16px] gap-[32px]">
        {/* Sqaure corners */}
        <div className="absolute w-[12px] h-[12px] bg-[#4284FF] -left-[6px] -top-[6px]" />
        <div className="absolute w-[12px] h-[12px] bg-[#4284FF] -right-[6px] -top-[6px]" />
        <div className="absolute w-[12px] h-[12px] bg-[#4284FF] -left-[6px] -bottom-[6px]" />
        <div className="absolute w-[12px] h-[12px] bg-[#4284FF] -right-[6px] -bottom-[6px]" />

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[32px]">
          {/* Depins text */}
          <div className="col-span-1 sm:col-span-2 row-span-1 lg:col-span-1 lg:row-span-2 flex flex-col items-center justify-start text-center w-full h-full">
            <div className="relative flex items-center justify-center pb-[64px]">
              {/* Main Text */}
              <h1 className="text-blue-500 font-black text-[77px] relative z-10">
                DEPINS
              </h1>
              {/* Shadow Layers */}
              {[...Array(4)].map((_, index) => {
                let translateY;
                switch (index) {
                  case 0:
                    translateY = 60; // First layer
                    break;
                  case 1:
                    translateY = 40; // Second layer
                    break;
                  case 2:
                    translateY = 20; // Third layer
                    break;
                  case 3:
                    translateY = 0; // Fourth layer
                    break;
                  default:
                    translateY = index * 30;
                }
                return (
                  <h1
                    key={index}
                    className="absolute font-black text-[77px] text-black opacity-10 drop-shadow-[0_0_1px_rgba(255,255,255,1)]"
                    style={{
                      transform: `translateY(${translateY}px)`,
                      opacity: 1 - index * 0.1,
                    }}
                  >
                    DEPINS
                  </h1>
                );
              })}
            </div>
          </div>

          {/* Animation circle */}
          <Reveal className="col-span-1 sm:col-span-2 lg:col-span-1 row-span-1 lg:row-span-5 lg:col-start-1 lg:row-start-3 flex h-[150px] md:h-[300px] lg:h-full border-2 border-[#4284FF] overflow-clip items-center justify-center">
            <video className="w-full h-full object-cover" muted autoPlay loop>
              <source src="/animations/depins_grid_3.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Reveal>

          {/* Info */}
          <Reveal className="col-span-1 sm:col-span-2 row-span-1 lg:row-start-1 lg:col-start-2 relative border-x-2 border-[#4284FF] overflow-hidden">
            <div className="absolute flex w-full h-full justify-center">
              <img
                src="/images/info-arrow-left.svg"
                className="w-full h-full object-cover -left-[600px]"
                alt="arrow-left"
              />
              <img
                src="/images/info-arrow-right.svg"
                className="w-full h-full -right-[600px] object-cover"
                alt="arrow-right"
              />
            </div>
            <Marquee className="flex w-full py-[32px]" autoFill>
              <div className="flex justify-center ml-[32px] gap-[32px] items-center text-xl font-semibold">
                <p>!</p>
                <p>INFO</p>
              </div>
            </Marquee>
          </Reveal>

          {/* Depins Overview */}
          <Reveal className="col-span-1 sm:col-span-2 lg:row-span-2 lg:row-start-2 lg:col-start-2 flex flex-col border-2 border-[#4284FF] p-[16px] md:p-[24px] gap-[8px]">
            <h3>DePINs Overview</h3>
            <p className="text-white">
              DePINs enable community-driven infrastructure by using blockchain
              to coordinate and reward participants for sharing physical
              resources like bandwidth, storage, and energy. They replace
              centralized control with decentralized governance, creating
              resilient, transparent, and incentivized networks that scale
              globally.
            </p>
          </Reveal>

          {/* Text animation */}
          <Reveal className="col-span-1 row-span-1 sm:col-span-2 flex flex-col border-2 border-[#4284FF] justify-center p-[24px]">
            <TypeWriting textArray={logArray} delay={30} />
          </Reveal>

          {/* Warning pattern */}
          <Reveal className="col-span-1 sm:col-span-2 row-span-1 h-[32px]">
            <img
              src="/images/line.svg"
              alt="warning"
              className="w-full h-full object-cover"
            />
          </Reveal>

          {/* Coin animation */}
          <Reveal className="col-span-1 row-span-1 sm:row-span-2 border-2 border-[#4284FF]">
            <video width="600" muted autoPlay loop>
              <source src="/animations/coin.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Reveal>

          {/* Claim Button */}
          <Reveal className="col-span-1 row-span-1">
            {/* <Button
              className="w-full h-full border-2 border-[#4284FF] bg-black text-[#4284FF] text-xl py-[24px] hover:text-white"
              onClick={() => {
                if (address) {
                  navigate(CLAIM);
                } else {
                  navigate(LIVE);
                }
              }}
            >
              Claim
            </Button> */}
            <p className="flex w-full h-full border-2 border-[#4284FF] bg-black text-[#4284FF] text-xl py-[24px] justify-center items-center">
              Claim End
            </p>
          </Reveal>

          {/* Wave */}
          <Reveal className="col-span-1 row-span-1 flex justify-center w-full h-full border-2 border-[#4284FF] overflow-clip">
            <video className="w-full h-full object-cover" muted autoPlay loop>
              <source src="/animations/depins_wave.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Reveal>
        </div>

        <div className="flex justify-between items-center gap-[24px]">
          <p>DEXSCREENER</p>
          <div className="w-full border-b border-[#4284FF]" />
          <Icon icon="ion:globe-outline" className="w-[48px] h-[48px]" />
          <div className="w-full border-b border-[#4284FF]" />
          <p>CHART</p>
        </div>

        <div className="w-full h-[80vh] min-h-[600px] md:h-full border-2 border-[#4284FF]">
          <div id="dexscreener-embed">
            <iframe src="https://dexscreener.com/iotex/0x55675984d54BAe52354320894eA1864aF952cbbc?embed=1&theme=dark"></iframe>
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default AnimationSection;
