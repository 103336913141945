import React, { useEffect, useState } from "react";
import Button from "../Button";
import {
  useAccount,
  useReadContracts,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import staking_token_abi from "../../hooks/abi/staking_token_abi";
import Reveal from "../Animation/Reveal";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import new_staking_abi from "../../hooks/abi/new_staking_abi";
import LoadingInComponent from "../LoadingInComponent";
import CountdownTimer from "../CountdownTimer";
import { concat, ethers } from "ethers";
import useShorten from "../../hooks/shorten";

const FlexibleForm = ({ toggleWalletModal }) => {
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [showError, setShowError] = useState(false);
  const [flexibleStakeData, setFlexibleStakeData] = useState(null);
  const [targetDocID, setTargetDocID] = useState(null);
  const [targetDocData, setTargetDocData] = useState(null);

  const shorten = useShorten();
  const { address } = useAccount();
  const { data: stakeBalance } = useReadContracts({
    contracts: [
      {
        abi: staking_token_abi,
        functionName: "balanceOf",
        address: process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
        args: [address],
      },
    ],
  });

  // Approve
  const {
    writeContract: approve,
    data: approveData,
    error: approveError,
  } = useWriteContract();
  // Approve Receipt
  const { data: approveReceipt, status: approveReceiptStatus } =
    useWaitForTransactionReceipt({
      hash: approveData,
      enabled: Boolean(approveData),
    });

  // Stake
  const {
    writeContract: stake,
    data: stakeData,
    error: stakeError,
  } = useWriteContract();
  // Stake Receipt
  const { data: stakeReceipt, status: stakeReceiptStatus } =
    useWaitForTransactionReceipt({
      hash: stakeData,
      enabled: Boolean(stakeData),
    });

  // Unstake
  const {
    writeContract: unstake,
    data: unstakeData,
    error: unstakeError,
  } = useWriteContract();
  // Unstake Receipt
  const { data: unstakeReceipt, status: unstakeReceiptStatus } =
    useWaitForTransactionReceipt({
      hash: unstakeData,
      enabled: Boolean(stakeData),
    });

  // Withdraw
  const {
    writeContract: withdraw,
    data: withdrawData,
    error: withdrawError,
    status: withdrawStatus,
  } = useWriteContract();
  // Withdraw Receipt
  const { data: withdrawReceipt, status: withdrawReceiptStatus } =
    useWaitForTransactionReceipt({
      hash: withdrawData,
      enabled: Boolean(withdrawData),
    });

  // Balance Index
  const { data: balanceIndex } = useReadContracts({
    contracts: [
      {
        abi: new_staking_abi,
        functionName: "balanceOf",
        address: process.env.REACT_APP_DEPINS_STAKING_CONTRACT_ADDRESS,
        args: [address],
      },
    ],
  });
  // tokenOfOwnerByIndex
  const { data: tokenID } = useReadContracts({
    contracts: [
      {
        abi: new_staking_abi,
        functionName: "tokenOfOwnerByIndex",
        address: process.env.REACT_APP_DEPINS_STAKING_CONTRACT_ADDRESS,
        args: [address, targetDocData?.tokenIndex],
      },
    ],
    enabled: Boolean(flexibleStakeData && targetDocData !== null),
  });

  const textHead = [
    ">_ ",
    <span className="text-[#4284FF]">Staking Mode: </span>,
    "Flexible\n",
    ">_ ",
    <span className="text-[#4284FF]">Cooldown To Claim: </span>,
    "48 Hours\n",
    ">_ ",
    <span className="text-[#4284FF]">Annual Percentage Yield (APY): </span>,
    "25.00%\n",
    ">_ ",
    <span className="text-[#4284FF]">Unstake Token: </span>,
    "Unstake Anytime\n",
  ];
  const binobitFormulaText = [
    ">_ Binobit Points = Staked Amount * Days Staked * 0.025\n",
  ];
  const stakePercentage = [
    { title: "25%", value: 0.25 },
    { title: "50%", value: 0.5 },
    { title: "75%", value: 0.75 },
    { title: "Max", value: 1 },
  ];

  // Textbox changes
  const handleChange = (e) => {
    setAmount(e.target.value);
  };
  // Count days staked
  const daysStaked = () => {
    if (!targetDocData.stakeDate.seconds) return 0;

    const stakeDate = new Date(targetDocData.stakeDate.seconds * 1000);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInTime = currentDate.getTime() - stakeDate.getTime();

    // Convert the difference in milliseconds to days
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  };
  // Fetching Flexible Stake Data
  const fetchFlexibleStakeData = async () => {
    try {
      const q = query(
        collection(db, "staking"),
        where("walletAddress", "==", address),
        where("stakeType", "==", 1),
        where("claimDate", "==", null),
        where("claimAmount", "==", null),
        where("claimHash", "==", null),
        orderBy("stakeDate", "desc")
      );

      let querySnapshot;
      querySnapshot = await getDocs(q);

      let finalData = [];

      querySnapshot.forEach((element) => {
        const docId = element.id;
        const docData = element.data();

        const concatData = {
          ...docData,
          id: docId,
        };

        finalData.push(concatData);
      });

      setFlexibleStakeData(finalData);
    } catch (e) {
      console.error("Error fetching document: ", e);
      console.log("No data found");
    }
  };

  // Run stake function through approve
  const flexibleStake = () => {
    setLoading(true);
    if (amount !== "") {
      setShowError(false);
      stakeToken();
    } else {
      setLoading(false);
      setShowError(true);
    }
  };
  // Stake token function
  const stakeToken = async () => {
    setLoading(true);
    try {
      approve({
        abi: new_staking_abi,
        address: process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
        functionName: "approve",
        args: [
          process.env.REACT_APP_DEPINS_STAKING_CONTRACT_ADDRESS,
          process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
        ],
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // Submit Stake Data (ON STAKE SUCCESS)
  const submitStakeData = async () => {
    await addDoc(collection(db, "staking"), {
      binobitEarned: null,
      claimAmount: null,
      claimDate: null,
      claimHash: null,
      daysStaked: null,
      depinsEarned: null,
      stakeAmount: ethers.parseEther(amount.toString()).toString(),
      stakeDate: new Date(),
      stakeHash: stakeData,
      stakeType: 1,
      tokenIndex: Number(balanceIndex[0].result),
      unstaked: false,
      unstakedDate: null,
      unstakeHash: null,
      walletAddress: address,
    });
    window.location.reload();
  };

  // Unstake token function
  const unstakeToken = async () => {
    setLoading(true);
    try {
      if (targetDocData !== null && tokenID[0].result) {
        unstake({
          abi: new_staking_abi,
          address: process.env.REACT_APP_DEPINS_STAKING_CONTRACT_ADDRESS,
          functionName: "unstake",
          args: [Number(tokenID[0].result)],
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // Update Unstake Data (ON UNSTAKE)
  const updateUnstakeData = async () => {
    try {
      const docRef = doc(db, "staking", targetDocID);
      await updateDoc(docRef, {
        unstaked: true,
        unstakedDate: new Date(),
        unstakeHash: unstakeData,
        daysStaked: daysStaked(),
      });
      console.log("Document updated successfully");
      window.location.reload();
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  // Withdraw token function
  const withdrawToken = async () => {
    setLoading(true);
    try {
      if (flexibleStakeData !== null) {
        withdraw({
          abi: new_staking_abi,
          address: process.env.REACT_APP_DEPINS_STAKING_CONTRACT_ADDRESS,
          functionName: "withdraw",
          args: [Number(tokenID[0].result)],
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // Update Claim Data (ON WITHDRAW)
  const updateClaimData = async () => {
    try {
      const docRef = doc(db, "staking", targetDocID);
      await updateDoc(docRef, {
        claimAmount: ethers.formatEther(targetDocData.stakeAmount),
        claimDate: new Date(),
        claimHash: withdrawData,
        depinsEarned: ethers.formatEther(targetDocData.stakeAmount),
        binobitEarned: ethers.formatEther(
          targetDocData.stakeAmount * targetDocData.daysStaked * 0.025
        ),
      });
      console.log("Document updated successfully");
      window.location.reload();
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  // After approve run stake
  useEffect(() => {
    if (approveReceipt && approveReceiptStatus === "success") {
      stake({
        abi: new_staking_abi,
        address: process.env.REACT_APP_DEPINS_STAKING_CONTRACT_ADDRESS,
        functionName: "stake",
        args: [1, ethers.parseEther(amount.toString())],
      });
    }
  }, [approveReceipt]);
  // After stake run Submit Stake Data
  useEffect(() => {
    if (stakeReceipt && stakeReceiptStatus === "success" && balanceIndex) {
      setLoading(false);
      submitStakeData();
    }
  }, [stakeReceipt]);
  // After unstaked run Update Unstake Data
  useEffect(() => {
    if (unstakeReceipt && unstakeReceiptStatus === "success") {
      setLoading(false);
      updateUnstakeData();
    }
  }, [unstakeReceipt]);
  // After withdraw run Update Claim Data
  useEffect(() => {
    if (withdrawReceipt && withdrawReceiptStatus === "success") {
      setLoading(false);
      updateClaimData();
    }
  }, [withdrawReceipt]);
  // On error set loading to false
  useEffect(() => {
    if (withdrawError || approveError || stakeError || unstakeError) {
      setLoading(false);
      window.location.reload();
    }
  }, [withdrawError, approveError, stakeError, unstakeError]);
  // Fetch Flexible Stake Data
  useEffect(() => {
    fetchFlexibleStakeData();
    if (!address) {
      setFlexibleStakeData(null);
      setAmount("");
    }
  }, []);
  useEffect(() => {
    setTargetDocData(
      flexibleStakeData?.find((data) => data.id === targetDocID)
    );
  }, [targetDocID]);
  useEffect(() => {
    if (tokenID && action && targetDocData) {
      if (action === "unstake") {
        unstakeToken();
      } else if (action === "withdraw") {
        withdrawToken();
      }
    }
  }, [tokenID, action, targetDocData]);

  return (
    <Reveal className="flex flex-col w-full gap-[64px] items-center">
      <div className="border-2 border-[#4284FF] flex flex-col w-full max-w-[704px] p-[32px] bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)] gap-[24px]">
        <div className="whitespace-pre-wrap">
          {textHead.map((part, index) => (
            <React.Fragment key={index}>{part}</React.Fragment>
          ))}
        </div>
        <div className="flex flex-col w-full gap-[8px]">
          <p>{">_ Add $DePINs"}</p>
          <div className="flex w-full border border-white p-[12px]">
            <input
              type="text"
              name="depinsAddress"
              value={amount}
              disabled={!address}
              className="w-full text-white bg-black outline-none placeholder:opacity-20"
              placeholder="0.00"
              onChange={handleChange}
            />
            <img
              src="/images/depins-round-icon.svg"
              alt="depins-icon"
              className="w-[24px] h-[24px]"
            />
          </div>
          <div className="flex w-full gap-[8px]">
            {stakePercentage.map((percentage, index) => (
              <Button
                key={index}
                variant="tertiary"
                className="border border-[#4284FF] bg-black w-full"
                disabled={!address}
                onClick={() => {
                  setAmount(
                    stakeBalance[0].result
                      ? Number(ethers.formatEther(stakeBalance[0].result)) *
                          percentage.value
                      : 0
                  );
                }}
              >
                {percentage.title}
              </Button>
            ))}
          </div>
          {stakeBalance && (
            <div className="flex w-full justify-between items-center">
              <p>Balance:</p>
              <p>
                <span className="text-[#4284FF]">
                  {stakeBalance[0].result
                    ? Number(ethers.formatEther(stakeBalance[0].result))
                    : 0}
                </span>{" "}
                $DePINs
              </p>
            </div>
          )}
          {showError && (
            <p className="text-[#FF0000]">Please key in an amount to stake.</p>
          )}
        </div>
        {/* <div className="whitespace-pre-wrap">
          {binobitFormulaText.map((part, index) => (
            <React.Fragment key={index}>{part}</React.Fragment>
          ))}
        </div> */}
        <LoadingInComponent isLoading={loading}>
          {address ? (
            <Button
              variant="primary"
              colour="blue"
              onClick={flexibleStake}
              className="w-full"
            >
              Stake
            </Button>
          ) : (
            <Button
              variant="primary"
              colour="blue"
              onClick={toggleWalletModal}
              className="w-full"
            >
              Connect Wallet
            </Button>
          )}
        </LoadingInComponent>
      </div>

      {/* User DePINs */}
      {address && (
        <LoadingInComponent isLoading={loading}>
          <div className="flex flex-col w-full gap-[24px]">
            <h2>Your $DePINs Stakes</h2>
            <div className="border-2 border-[#4284FF] flex flex-col w-full bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)] gap-[24px] overflow-x-auto">
              <table>
                <thead>
                  <tr className="border-b border-[#FFFFFF33] text-[#FFFFFFCC] text-nowrap">
                    <th className="font-normal py-[12px] px-[16px] text-start">
                      Stake Hash
                    </th>
                    <th className="font-normal py-[12px] px-[16px] text-start">
                      APY
                    </th>
                    <th className="font-normal py-[12px] px-[16px] text-start">
                      Staked Amount
                    </th>
                    {/* <th className="font-normal py-[12px] px-[16px] text-start">
                      DePINs Earned
                    </th>
                    <th className="font-normal py-[12px] px-[16px] text-start">
                      Binobit Earned
                    </th> */}
                    <th className="font-normal py-[12px] px-[16px] text-start">
                      Stake Date
                    </th>
                    <th className="font-normal py-[12px] px-[16px] text-start">
                      Withdraw Cooldown
                    </th>
                    <th className="font-normal py-[12px] px-[16px] text-start"></th>
                  </tr>
                </thead>
                {flexibleStakeData &&
                (flexibleStakeData === null ||
                  flexibleStakeData.length === 0) ? (
                  <tbody>
                    <tr>
                      <td
                        className="py-[12px] px-[16px] text-center"
                        colSpan="8"
                      >
                        No data found
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {flexibleStakeData &&
                      flexibleStakeData.map((data, index) => (
                        <tr key={index}>
                          <td className="py-[12px] px-[16px]">
                            {data.stakeHash ? (
                              <a
                                href={`https://testnet.iotexscan.io/tx/${data.stakeHash}`}
                                target="_blank"
                                className="hover:underline"
                              >
                                {shorten(data.stakeHash, 5, 3)}
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="py-[12px] px-[16px]">15%</td>
                          <td className="py-[12px] px-[16px]">
                            {Number(ethers.formatEther(data.stakeAmount)) ??
                              "-"}
                          </td>
                          {/* <td className="py-[12px] px-[16px]">
                            {Number(ethers.formatEther(data.stakeAmount)) ??
                              "-"}
                          </td>
                          <td className="py-[12px] px-[16px]">
                            {Number(ethers.formatEther(data.stakeAmount)) &&
                            data.daysStaked !== null
                              ? Number(ethers.formatEther(data.stakeAmount)) *
                                data.daysStaked *
                                0.025
                              : "-"}
                          </td> */}
                          <td className="py-[12px] px-[16px]">
                            {data.stakedDate !== null
                              ? new Date(
                                  data.stakeDate.seconds * 1000
                                ).toLocaleDateString("en-UK")
                              : "-"}
                          </td>
                          <td className="py-[12px] px-[16px]">
                            {data.unstakedDate !== null ? (
                              <CountdownTimer
                                date={
                                  new Date(
                                    data.unstakedDate.seconds * 1000 +
                                      2 * 24 * 60 * 60 * 1000
                                  )
                                }
                                completedText="-"
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="py-[12px] px-[16px]">
                            {data.unstakedDate !== null ? (
                              <Button
                                variant="tertiary"
                                className="border border-[#4284FF] bg-black w-full"
                                disabled={
                                  new Date(
                                    data.unstakedDate.seconds * 1000 +
                                      2 * 24 * 60 * 60 * 1000
                                  ) > new Date()
                                }
                                onClick={() => {
                                  setTargetDocID(data.id);
                                  setAction("withdraw");
                                }}
                              >
                                Withdraw
                              </Button>
                            ) : (
                              <Button
                                variant="tertiary"
                                className="border border-[#4284FF] bg-black w-full"
                                onClick={() => {
                                  setTargetDocID(data.id);
                                  setAction("unstake");
                                }}
                              >
                                Unstake
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </LoadingInComponent>
      )}
    </Reveal>
  );
};

export default FlexibleForm;
