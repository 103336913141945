import React, { useEffect, useState } from "react";
import MainComponent from "../Component/MainComponent";
import Reveal from "../Component/Animation/Reveal";
import Button from "../Component/Button";
import Modal from "../Component/Modal";
import { Icon } from "@iconify/react/dist/iconify.js";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import Marquee from "react-fast-marquee";
import CountdownTimer from "../Component/CountdownTimer";
import { useNavigate } from "react-router-dom";
import { HOME } from "../Router/path";

const DexxVictim = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [toolsEnabled, setToolsEnabled] = useState(true);
  const date = new Date("2024-11-27T23:59:59Z");
  const [formData, setFormData] = useState({
    walletAddress: "",
    iotexAddress: "",
  });

  useEffect(() => {
    const target = new Date(date).getTime() - 8 * 60 * 60 * 1000;
    const now = new Date().getTime();

    if (now >= target) {
      setToolsEnabled(false);
    }
  }, [date]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const handleSubmit = async () => {
    await setDoc(doc(db, "dexx_victims", formData.walletAddress), {
      walletAddress: formData.walletAddress,
      iotexAddress: formData.iotexAddress,
      dateSubmitted: new Date(),
    });
    toggleSuccessModal();
  };

  const textP1 = [
    ">_ ",
    <span className="text-[#4284FF]">SYSTEM ALERT: </span>,
    "DEXX AI Security Breach Detected ⚠️.\n",
    ">_ ",
    <span className="text-[#4284FF]">STATUS: </span>,
    "Critical\n",
    ">_ ",
    <span className="text-[#FF3030]">USER TOKENS COMPROMISED</span>,
    "\n\n>_ Your address may be affected by this attack. To assist in the recovery process:",
  ];

  const textP2 = [
    "NOTE:\n",
    ">_ ",
    <span className="text-[#4284FF]">All </span>,
    "submissions are confidential.\n",
    ">_ No private keys or seed phrases will ever requested.",
  ];

  const textDisabled = [
    ">_ ",
    <span className="text-[#4284FF]">SYSTEM ALERT: </span>,
    "DEXX AI breach has been resolved. ✅.\n",
    ">_ ",
    <span className="text-[#4284FF]">STATUS: </span>,
    "Normal\n",
    ">_ ",
    <span className="text-[#4284FF]">USER TOKENS SECURED</span>,
    "\n\n>_ Thank you for your patience during this incident.\n",
    ">_ Recovery tools have been ",
    <span className="text-[#4284FF]">disabled</span>,
    ".\n>_ If you require further assistance, contact support directly.\n\n",
    "NOTICE:\n",
    ">_ ",
    <span className="text-[#4284FF]">All </span>,
    "compromised vulnerabilities have been patched.\n",
    ">_ User tokens are now secure.",
  ];

  return (
    <MainComponent>
      {successModal && (
        <Modal toggleModal={toggleSuccessModal}>
          <div className="flex flex-col w-full h-full gap-[32px]">
            <div className="flex flex-col w-full items-center text-[#4284FF]">
              <Icon
                icon="teenyicons:tick-circle-outline"
                className="w-[56px] h-[56px]"
              />
              <h1 className="text-lg">SUCCESS</h1>
            </div>
            <div className="flex flex-col w-full items-center text-center">
              <p>
                Your address has been submitted successfully! Please wait
                patiently for our review.
              </p>
            </div>
            <div className="flex flex-col w-full items-center">
              <Button
                variant="primary"
                colour="blue"
                className="w-fit"
                onClick={() => {
                  navigate(HOME);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Reveal
        className="flex w-full md:h-full items-center justify-center px-[16px]"
        style={{
          backgroundImage: toolsEnabled
            ? "url('/images/victim-bg-red.png')"
            : "url('/images/victim-bg-blue.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {toolsEnabled ? (
          <div className="flex flex-col border-2 border-red-500 w-[704px] p-[32px] bg-black drop-shadow-[0_0_12px_rgba(255,48,48,32)] gap-[24px] mt-[110px]">
            <p className="whitespace-pre-wrap">
              {textP1.map((part, index) => (
                <React.Fragment key={index}>{part}</React.Fragment>
              ))}
            </p>
            <div className="relative flex w-full items-center justify-center border-x-2 border-red-500 ">
              <Marquee className="flex w-full" direction="right" autoFill>
                <img
                  src="/images/info-red-arrow-left.svg"
                  alt="red-arrow-left"
                />
              </Marquee>
              <Marquee className="flex w-full" direction="left" autoFill>
                <img
                  src="/images/info-red-arrow-right.svg"
                  alt="red-arrow-right"
                />
              </Marquee>
              <div
                className="absolute text-red-500 text-[32px]"
                style={{ fontFamily: "Array-Regular" }}
              >
                <CountdownTimer date={date} setToolsEnabled={setToolsEnabled} />
              </div>
            </div>
            <div className="flex flex-col w-full gap-[24px]">
              <div className="flex flex-col w-full gap-[8px]">
                <p>{`>_ Input your affected wallet address below.`}</p>
                <div className="w-full border border-white p-[12px]">
                  <input
                    type="text"
                    name="walletAddress"
                    className="w-full text-white bg-black outline-none placeholder:opacity-20"
                    placeholder="0x00000000000000000000000000000000000000"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full gap-[8px]">
                <p>{`>_ Input your IoTeX wallet address below. (EVM based wallet)`}</p>
                <div className="w-full border border-white p-[12px]">
                  <input
                    type="text"
                    name="iotexAddress"
                    className="w-full text-white bg-black outline-none placeholder:opacity-20"
                    placeholder="0x00000000000000000000000000000000000000"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <Button
                variant="primary"
                colour="blue"
                className="w-fit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
            <p className="whitespace-pre-wrap">
              {textP2.map((part, index) => (
                <React.Fragment key={index}>{part}</React.Fragment>
              ))}
            </p>
          </div>
        ) : (
          <Reveal className="border-2 border-[#4284FF] w-[704px] p-[32px] bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)] mt-24">
            <p className="whitespace-pre-wrap">
              {textDisabled.map((part, index) => (
                <React.Fragment key={index}>{part}</React.Fragment>
              ))}
            </p>
          </Reveal>
        )}
      </Reveal>
    </MainComponent>
  );
};

export default DexxVictim;
